<template>
  <div class="group relative flex h-full grow flex-col">
    <!-- IMAGE -->
    <div class="z-20 pl-[30%]">
      <div v-if="image" class="aspect-[33/25] w-full overflow-hidden">
        <AppImage
          loading="lazy"
          class="rounded-[0.625rem]"
          :src="image"
          :aspect-ratio="33 / 25"
        />
      </div>

      <!-- IMAGE PLACEHOLDER -->
      <div
        v-else
        class="relative flex aspect-[33/25] h-full w-full items-center justify-center rounded-[0.625rem] p-4 blue-el:bg-blue-500 green-el:bg-green-500 pink-el:bg-pink-500 sm:p-6"
      >
        <Component :is="componentName" class="-mt-10 h-1/2 w-auto text-white" />
      </div>
    </div>
    <!-- TEXT -->
    <div
      class="z-10 -mt-10 flex h-[inherit] flex-col justify-between rounded-[0.625rem] bg-white p-6 pt-[3.75rem] text-black sm:p-9 sm:pt-[4.75rem]"
    >
      <div>
        <h3
          class="mb-5 text-[1.875rem] font-medium leading-9 sm:mb-8 sm:text-xl sm:leading-10 xl:text-2xl"
        >
          {{ title }}
        </h3>

        <div
          v-if="data.lead"
          class="max-h-[5.6875rem] text-sm leading-6 line-clamp-3 sm:text-[1.25rem] sm:leading-7"
          v-html="data.lead"
        />
      </div>
      <div class="mt-5 whitespace-nowrap text-xs sm:-mb-1 sm:mt-8">
        <AppLink
          :aria-label="`Link zur Seite: ${title}`"
          :to="data.url"
          class="text-theme font-medium before:absolute before:inset-0 before:cursor-pointer focus:after:!bg-transparent"
          >Mehr erfahren</AppLink
        >
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const hero = computed(() => {
  if (Array.isArray(props.data.heros) && props.data.heros.length) {
    return props.data.heros[0]
  }
  return null
})

const title = computed(() => {
  if (hero.value) {
    return hero.value.heading
  }
  return props.data.title
})

const image = computed(() => {
  if (props.data.image) {
    return props.data.image
  }

  if (hero.value) {
    if (hero.value.file && hero.value.file.is_image) {
      return hero.value.file
    }
    return hero.value.image
  }
  return null
})

const componentName = computed(() => {
  if (props.data.color_theme?.value === 'green') {
    return 'IconEar'
  } else if (props.data.color_theme?.value === 'pink') {
    return 'IconEye'
  }

  return 'IconEyeAndEarFull'
})
</script>
